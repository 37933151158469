import {useFrame, useThree} from "react-three-fiber";
import {BloomEffect, EffectComposer, EffectPass, RenderPass, VignetteEffect} from "postprocessing";
import {HalfFloatType} from "three";

export const Renderer = () => {
  const { gl, camera, size, scene } = useThree();

  useFrame((context, delta) => {
    gl.setClearColor(0xd0d3cf);
    gl.clear();
    gl.render(scene, camera);
  }, 100);
  return null;
};

export const PostRenderer = () => {
  const { gl, camera, size, scene } = useThree();

  const composer = new EffectComposer(gl, { frameBufferType: HalfFloatType, multisampling: 4 });
  composer.addPass(new RenderPass(scene, camera));
  composer.addPass(
    new EffectPass(
      camera,
      new BloomEffect({
        intensity: 0.5,
      }),
      new VignetteEffect({
        darkness: 0.2,
      }),
    ),
  );

  useFrame((context, delta) => {
    gl.setClearColor(0xd0d3cf);
    gl.clear();
    composer.render(delta);
  }, 100);
  return null;
};
