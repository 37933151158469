import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Stage from "./Stage";

const App = () => (
  <Provider store={store}>
    <Stage />
  </Provider>
);

const root = document.createElement('div');
document.body.appendChild(root);

ReactDOM.render(<App />, root);

export default App;
