import React, { Suspense, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { GlobalStyle } from 'styles';
import { Canvas, extend, useFrame, useThree } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Renderer } from 'Renderer';
import Ring from '../Ring';
import { Vector3 } from 'three';
extend({ OrbitControls });

export const ContainerStage = styled.div`
  height: 100vh;
`;

function Camera(props) {
  const ref = useRef();
  const { setDefaultCamera, camera } = useThree();
  console.log(camera);
  const lookat = new Vector3(0, 0.1, 0.0);
  // ref.current.lookAt(lookat);
  useEffect(() => void setDefaultCamera(ref.current), []);
  console.log(ref.current);
  useFrame(() => ref.current.updateMatrixWorld());
  return <perspectiveCamera ref={ref} {...props} />;
}

function Controls(props) {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const orbit = useRef();
  useFrame(() => {
    orbit.current && orbit.current.update();
  });
  return (
    <orbitControls
      ref={orbit}
      args={[camera, domElement]}
      enableDamping
      enablePan={false}
      rotateSpeed={2}
      dampingFactor={0.05}
      maxPolarAngle={Math.PI / 2.2}
      minPolarAngle={Math.PI / 8}
      minDistance={0.5}
      maxDistance={1.5}
      zoomSpeed={0.5}
    />
  );
}

export default function Stage() {
  return (
    <ContainerStage>
      <GlobalStyle />
      <Canvas pixelRatio={2}>
        <Suspense fallback={null}>
          <Camera position={[0, 0, 1]} rotation={[0, 0, 0]} fov={25} />
          <Controls />
          <Ring position={[0, -0.03, 0]} />
          <Renderer />
        </Suspense>
      </Canvas>
    </ContainerStage>
  );
}
