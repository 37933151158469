import matcapFrag from './shader/matCap.frag';
import matcapVert from './shader/matcap.vert';
import diamondFrag from './shader/diamond.frag';
import diamandVert from './shader/diamond.vert';
import floorFrag from './shader/floor.frag';
import floorVert from './shader/floor.vert';
import domeFrag from './shader/dome.frag';
import domeVert from './shader/dome.vert';

import matcapReflectionTex from 'Assets/png/metal_reflection.png';
import matcapReflectionBlurTex from 'Assets/png/metal_reflection_blur.png';
import matcapHighlightTex from 'Assets/png/metal_highlight.png';
import matcapHighlightBlurTex from 'Assets/png/metal_highlight_blur.png';

import silverReflectionTex from 'Assets/png/silver_reflection.png';
import silverReflectionBlurTex from 'Assets/png/silver_reflection_blur.png';
import silverHighlightTex from 'Assets/png/silver_highlight.png';
import silverHighlightBlurTex from 'Assets/png/silver_highlight_blur.png';

import plattformReflectionTex from 'Assets/png/plattform_reflection.png';
import plattformReflectionBlurTex from 'Assets/png/plattform_reflection_blur.png';
import plattformHighlightTex from 'Assets/png/plattform_highlight.png';
import plattformHighlightBlurTex from 'Assets/png/plattform_highlight_blur.png';

import ringNormalMap from 'Assets/Wrinkle_Map.jpg';
import platformReflectionTex from 'Assets/Plattform_Reflection.jpg';

import ringAoTex from 'Assets/png/Ring_AO2.png';
import fassungAoTex from 'Assets/png/Fassung_AO2.png';
import PlattformAoTex from 'Assets/png/Plattform_AO.png';
import metalMaskTex from 'Assets/png/metal_mask.png';
import diamondFireTex from 'Assets/png/diamond_fire.png';
import diamondFlareTex from 'Assets/png/diamond_flare.png';
import diamondReflectionTex from 'Assets/png/diamond_reflection.png';
import diamondNormalTex from 'Assets/png/diamond_normal.png';
import floorAoTex from 'Assets/png/Ground_AO.png';
import { useLoader } from 'react-three-fiber';
import { Color, DoubleSide, RepeatWrapping, ShaderMaterial, TextureLoader, Vector2 } from 'three';

export function OverrideMaterials(scene) {
  const [
    metalMask,
    matcapReflection,
    matcapReflectionBlur,
    matcapHighlight,
    matcapHighlightBlur,
    diamondReflection,
    diamondNormal,
    diamondFire,
    diamondFlare,
  ] = useLoader(TextureLoader, [
    metalMaskTex,
    matcapReflectionTex,
    matcapReflectionBlurTex,
    matcapHighlightTex,
    matcapHighlightBlurTex,
    diamondReflectionTex,
    diamondNormalTex,
    diamondFireTex,
    diamondFlareTex,
  ]);

  const [
    silverReflection,
    silverReflectionBlur,
    silverHighlight,
    silverHighlightBlur,
    ringNormal,
    plattformReflection,
    plattformReflectionBlur,
    plattformHighlight,
    plattformHighlightBlur,
    platformReflection,
  ] = useLoader(TextureLoader, [
    silverReflectionTex,
    silverReflectionBlurTex,
    silverHighlightTex,
    silverHighlightBlurTex,
    ringNormalMap,
    plattformReflectionTex,
    plattformReflectionBlurTex,
    plattformHighlightTex,
    plattformHighlightBlurTex,
    platformReflectionTex,
  ]);
  const [ringAo, floorAo, fassungAo, PlattformAo] = useLoader(TextureLoader, [
    ringAoTex,
    floorAoTex,
    fassungAoTex,
    PlattformAoTex,
  ]);

  diamondReflection.wrapS = diamondReflection.wrapT = RepeatWrapping;
  diamondNormal.wrapS = diamondNormal.wrapT = RepeatWrapping;
  metalMask.wrapS = metalMask.wrapT = RepeatWrapping;
  diamondFire.wrapS = diamondFire.wrapT = RepeatWrapping;
  ringNormal.wrapS = ringNormal.wrapT = RepeatWrapping;

  diamondNormal.anisotropy = 2;

  scene.traverse(o => {
    if (o.material) {
      if (o.material.name === 'Ground_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: floorFrag,
          vertexShader: floorVert,
          transparent: true,
          polygonOffset: true,
          depthTest: true,
          polygonOffsetFactor: -1,
          polygonOffsetUnits: 0.1,
          uniforms: {
            diffuse: { value: new Color(0xd0d3cf) },
            ao: { value: floorAo },
          },
        });
        o.material = newMat;
      }

      if (o.material.name === 'Env_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: domeFrag,
          vertexShader: domeVert,
          uniforms: {
            diffuse: { value: new Color(0xd0d3cf) },
          },
        });
        o.material = newMat;
      }

      if (o.material.name === 'Gold_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: matcapFrag,
          vertexShader: matcapVert,
          extensions: { derivatives: true },
          uniforms: {
            diffuse: { value: new Color(0xf1d8a2) },
            intensity: { value: new Vector2(0.8, 0.03) },
            metalMaskSampler: { value: metalMask },
            matcapReflection: { value: matcapReflection },
            matcapReflectionBlur: { value: matcapReflectionBlur },
            matcapHighlight: { value: matcapHighlight },
            matcapHighlightBlur: { value: matcapHighlightBlur },
            normalMap: { value: ringNormal },
            ringAoSampler: { value: ringAo },
          },
        });
        o.material = newMat;
      }
      if (o.material.name === 'Logo_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: matcapFrag,
          vertexShader: matcapVert,
          visible: false,
          extensions: { derivatives: true },
          defines: { LOGO: '' },
          uniforms: {
            diffuse: { value: new Color(0xf1d8a2) },
            intensity: { value: new Vector2(0.8, 0.03) },
            metalMaskSampler: { value: metalMask },
            matcapReflection: { value: matcapReflection },
            matcapReflectionBlur: { value: matcapReflectionBlur },
            matcapHighlight: { value: matcapHighlight },
            matcapHighlightBlur: { value: matcapHighlightBlur },
            normalMap: { value: ringNormal },
            ringAoSampler: { value: ringAo },
          },
        });
        o.material = newMat;
      }
      if (o.material.name === 'Silver_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: matcapFrag,
          vertexShader: matcapVert,
          extensions: { derivatives: true },
          uniforms: {
            diffuse: { value: new Color(0xffffff) },
            highlightIntensity: { value: new Vector2(0.5, 0.05) },
            metalMaskSampler: { value: metalMask },
            matcapReflection: { value: silverReflection },
            matcapReflectionBlur: { value: silverReflectionBlur },
            matcapHighlight: { value: silverHighlight },
            matcapHighlightBlur: { value: silverHighlightBlur },
            normalMap: { value: ringNormal },
            ringAoSampler: { value: fassungAo },
          },
        });
        o.material = newMat;
      }
      if (o.material.name === 'Ring_Plattform_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: matcapFrag,
          vertexShader: matcapVert,
          extensions: { derivatives: true },
          defines: { PLATTFORM: '' },
          uniforms: {
            diffuse: { value: new Color(0xeae3aa) },
            highlightIntensity: { value: new Vector2(0.3, 0.05) },
            metalMaskSampler: { value: metalMask },
            matcapReflection: { value: plattformReflection },
            matcapReflectionBlur: { value: plattformReflectionBlur },
            matcapHighlight: { value: plattformHighlight },
            matcapHighlightBlur: { value: plattformHighlightBlur },
            normalMap: { value: ringNormal },
            ringAoSampler: { value: PlattformAo },
            plattformReflectionSampler: { value: platformReflection },
          },
        });
        o.material = newMat;
      }
      if (o.material.name === 'Diamond_MTL') {
        const isTransparent = o.material.opacity !== 1.0;
        const defines = {};
        if (isTransparent) defines.ISTRANSPARENT = '';

        const newMat = new ShaderMaterial({
          fragmentShader: diamondFrag,
          vertexShader: diamandVert,
          transparent: false,
          extensions: { derivatives: true },
          defines: { ...defines },
          side: DoubleSide,
          uniforms: {
            uOpacity: { value: 1.0 },
            diamondReflectionsSampler: { value: diamondReflection },
            diamondFireSampler: { value: diamondFire },
            diamondFlareSampler: { value: diamondFlare },
            diamondNormalSampler: { value: diamondNormal },
          },
        });
        o.material = newMat;
      }
      if (o.material.name === 'Diamond_Transp_MTL') {
        const newMat = new ShaderMaterial({
          fragmentShader: diamondFrag,
          vertexShader: diamandVert,
          transparent: true,
          side: DoubleSide,
          extensions: { derivatives: true },
          defines: { ISTRANSPARENT: '' },
          uniforms: {
            uOpacity: { value: 0.8 },
            diamondReflectionsSampler: { value: diamondReflection },
            diamondFireSampler: { value: diamondFire },
            diamondFlareSampler: { value: diamondFlare },
            diamondNormalSampler: { value: diamondNormal },
          },
        });
        o.material = newMat;
      }
    }
  });
}
