import React, { useRef } from 'react';
import { extend, useFrame, useLoader, useThree } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OverrideMaterials } from 'Materials';
import flareTex from 'Assets/png/Flare3.png';

extend({ OrbitControls });

import RingGLB from 'Assets/exp_tiffany_v18.glb';
import {
  AdditiveBlending,
  Euler,
  Quaternion,
  SpriteMaterial,
  Object3D,
  TextureLoader,
  Vector3,
} from 'three';

extend({ Object3D });
const Flare = props => {
  const flare = useLoader(TextureLoader, flareTex);

  const flareMat = new SpriteMaterial({
    map: flare,
    blending: AdditiveBlending,
    depthTest: false,
    depthWrite: false,
    // opacity: 0.5,
  });
  const ref = useRef();
  const pivot = useRef();

  const flarePos = new Vector3(0, 0.15, 0.0);
  const axis = new Vector3(0, 1, 0);

  const { camera } = useThree();
  useFrame(() => {
    const viewDir = new Vector3();
    camera.getWorldDirection(viewDir);
    let opacity = Math.abs(viewDir.normalize().dot(axis));
    if (opacity > 0.5) opacity += 0.1;
    opacity = Math.pow(opacity, 4.0);
    opacity *= 0.75;
    ref.current.material.opacity = opacity;
  });

  return (
    <group ref={pivot}>
      <sprite
        ref={ref}
        material={flareMat}
        position={flarePos}
        scale={new Vector3(0.12, 0.12, 0.12)}
      />
    </group>
  );
};

export default function Ring({ ...props }) {
  const { scene } = useLoader(GLTFLoader, RingGLB);
  OverrideMaterials(scene);
  return (
    <group dispose={null} {...props}>
      <primitive object={scene} />
      <Flare origin={scene} />
    </group>
  );
}
